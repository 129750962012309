
import { send } from 'emailjs-com';
import { useState } from 'react';

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    console.log(e)
    send(
      'service_navqax1',
      'template_za1revp',
      {
        name: name,
        email: email,
        message: message
      },
      '2Z1e66ZWlD-RbRdkm'
    )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>{props.data?.title}</h2>
                <p>
                  {props.data?.intro}
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nome'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Messaggio'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Invia
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-5 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contatti Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i>
                  Indirizzo
                </span>
                {props.data?.address}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i>
                  Telefono
                </span>{' '}
                <a href={'tel:' + props.data?.phone} className='phone'>{props.data?.phone}</a>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i>
                  Email
                </span>{' '}
                {props.data?.email}
              </p>
            </div>
          </div>
          <div className='col-md-12 map-container'>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2873.338872819538!2d10.914222!3d43.93166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a8bbf963e6a1d%3A0xb846c7c2540aed25!2sVia%20dell&#39;Ospizio%2C%2040%2C%2051100%20Pistoia%20PT!5e0!3m2!1sit!2sit!4v1649595138519!5m2!1sit!2sit"
              allowFullScreen
              className="map-frame"
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
              title='addressFrame' />
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>Copyright &copy;  Nora Gentili</p>
          <p>
            <a href='http://www.templatewire.com' rel='nofollow'>
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
