import useWindowDimensions from '../utils/useWindowDimensions';
import { Content } from './content'
import React from "react";


export const Service = ({ data, i }) => {
  const { height, width } = useWindowDimensions();

  const delay = (i * 500) + "ms";

  if (width >= 768) {
    if (i % 2 === 0) {
      return (
        <div key={`${data.title}-${i}`} className="col-md-8 col-md-offset-2 ">
          <div className='col-xs-12 col-md-4' >
            {' '}
            <img src={`img/${data.img}`} className='img-responsive img-full-page' alt='' style={{ animationDelay: delay }} />{' '}

          </div>

          <div className='col-xs-12 col-md-8 body body-full-page align-left' style={{ animationDelay: delay }}>
            {' '}
            <h3 className='title'>{data.title}</h3>
            <div className='content'>
              <Content data={data.filename} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div key={`${data.title}-${i}`} className="col-md-8 col-md-offset-2 ">
          <div className='col-xs-12 col-md-8  body body-full-page align-left' style={{ animationDelay: delay }} >
            {' '}
            <h3 className='title'>{data.title}</h3>
            <div className='content'>
              <Content data={data.filename} />
            </div>
          </div>
          <div className='col-xs-12 col-md-4' >
            {' '}
            <img src={`img/${data.img}`} className='img-responsive img-full-page' style={{ animationDelay: delay }} alt='' />{' '}

          </div>
        </div>
      );
    }
  }
  return (
    <div key={`${data.title}-${i}`}>

      <div className='col-xs-12 col-md-8 small-view' >
        <h3 className='title'>{data.title}</h3>
        <img src={`img/${data.img}`} className='img-responsive' alt='' />
        <div className='content'>
          <Content data={data.filename} />
        </div>
      </div>
    </div>
  );


}
