export const Navigation = (props) => {

  const handleNavCollapse = () => {
    const navBarCollapse = document.getElementById('nav-bar-collapse');
    const navbarToggler = document.getElementById('navbarToggler');
    if (window.innerWidth < 768 && navBarCollapse && navBarCollapse.ariaExpanded && navbarToggler) {
      navbarToggler.click();
    };
  }


  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            id="navbarToggler"
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#nav-bar-collapse'
            aria-controls='nav-bar-collapse'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            {props.data?.title}
            <div className='sub-brand'>
              {props.data?.subTitle}
            </div>
          </a>{' '}
        </div>

        <div className='collapse navbar-collapse' id='nav-bar-collapse' >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll' onClick={() => { handleNavCollapse(); props.setSection('Home'); }}>
                Chi Sono
              </a>
            </li>
            <li>
              <a href='#psychosynthesis' className='page-scroll' onClick={() => { handleNavCollapse(); props.setSection('Home'); }}>
                Psicosintesi
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll' onClick={() => { handleNavCollapse(); props.setSection('Services'); }}>
                Servizi
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll' onClick={() => { handleNavCollapse(); props.setSection('Home'); }}>
                Contatti
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
