export const Header = (props) => {


  return (

    <div id='header'>
      <div id='intro' className={window.innerWidth < 600 ? 'small-background' : ''}>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 pulse' id="intro-text">
                <h1>
                  {props.data?.title}
                  <span></span>
                </h1>
                <p>{props.data?.subTitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
