import { useEffect, useState } from "react";
import "./App.css";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import { Header } from "./components/header";
import { Navigation } from "./components/navigation";
import { Psychosynthesis } from "./components/psychosynthesis";
import { Services } from "./components/services";
import JsonData from "./data/data.json";



const App = () => {
  const HOME = 'Home';
  const SERVICES = 'Services';
  const HOME_SECTION = 'homeSection';
  const SERVICES_SECTION = 'servicesSection';
  const [landingPageData, setLandingPageData] = useState({});


  const setSection = ((value) => {
    const homeSection = document.getElementById(HOME_SECTION);
    if (homeSection) {
      homeSection.classList = value === HOME ? 'show-section' : 'hide-section';
    }

    const servicesSection = document.getElementById(SERVICES_SECTION);
    if (servicesSection) {
      servicesSection.classList = value === SERVICES ? 'show-section' : 'hide-section';
    }
  })

  const removeOverlay = () => {
    document.body.style.overflow = 'auto';
    const menu = document.getElementById('menu');
    if (menu) {
      menu.style.top = 0;
    }
    const intro = document.getElementById('intro');
    if (intro) {
      intro.style.height = 'auto';
      for (const overlay of intro.getElementsByClassName('overlay')) {
        for (const pulse of overlay.getElementsByClassName('pulse')) {
          pulse.classList.remove('pulse');

        }
        overlay.style.backgroundColor = '#00000033';
      }
    }
    const introText = document.getElementById('intro-text');
    if (introText) {
      if (window.innerWidth < 600) {
        const header = document.getElementById('header');
        header.style.paddingTop = '80px';
        introText.style.padding = '120px 0 0px 0';
      } else {
        introText.style.padding = '350px 0 200px 0';
      }
    }
  }

  const init = () => {
    const url = document.URL;

    if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
      removeOverlay();
    }

    if (url.includes('services')) {
      removeOverlay();
      setSection(SERVICES);


    } else {
      setTimeout(() => removeOverlay(), 3000);
    }
  }

  init();



  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation data={landingPageData.Header} setSection={(value) => setSection(value)} />
      <div id={HOME_SECTION} className='show-section'>
        <Header data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <Psychosynthesis data={landingPageData.Psychosynthesis} />
      </div>
      <div id={SERVICES_SECTION} className='hide-section'>
        <Services data={landingPageData.Services} />
      </div>
      <Contact data={landingPageData.Contact} />

    </div >
  );
};

export default App;
