import { Content } from "./content"

export const Psychosynthesis = (props) => {
  return (
    <div id='psychosynthesis' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>{props.data?.title}</h2>
          <div className="align-justify">
            <Content data={props.data?.content} />
          </div>
        </div>
      </div>
    </div>
  )
}
