// import React from "react";

export const createCodeHtml = (text) => {
  return { __html: text };
}


export const LoadContent = (fileName) => {
  console.log(fileName)
  if (fileName != null) {
    fetch(`./content/${fileName}`)
      .then((response) => response.text())
      .then((content) => {
        return content;
      })

  }
}

// export const LoadContent = (fileName) => {
//   const [content, setContent] = React.useState("");
//   React.useEffect(() => {
//     const fetchFileContent = async () => {
//       const response = await fetch(`./content/${fileName}`)
//       const { content } = await response.text();
//       consoo
//       setContent(content);
//     };
//     fetchFileContent();
//   }, []);

//   return (
//     <div>
//       {content}
//     </div>
//   );
// };