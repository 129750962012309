import { Content } from "./content";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-8 col-xs-offset-2 col-md-3 col-md-offset-0" >
            {" "}
            <img src="img/profile.jpg" className="img-responsive img-profile" alt="Nora Gentili" />{" "}
          </div>
          <div className="col-xs-12 col-md-9">
            <div className="about-text">
              <h2>{props.data?.title}</h2>
              <Content data={props.data?.intro} />
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data?.Why?.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data?.Why2?.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
