import { Content } from './content'
import { Service } from './service'

export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>{props.data?.title}</h2>
          <Content data={props.data?.intro} />
        </div>
        <div className='row'>
          {props.data?.content.map((d, i) =>
            <div className="row service-row">
              <Service data={d} i={i} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
