import React, { useEffect, useState } from 'react';
import { createCodeHtml } from '../utils';

export const Content = (filename) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(`/content/${filename.data}`)
      .then(res => res.text())
      .then(
        (result) => {
          setIsLoaded(true);
          setContent(result);
        },
      )
  })

  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div dangerouslySetInnerHTML={createCodeHtml(content)} className="content" />
    );
  }
}